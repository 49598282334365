import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';

import { GlobalService } from './global.service';
import { AuthService } from './auth.service';
import { TruthEngineSession } from '../dtos/session';
import { NotificationService } from './notification.service';
import { UserService } from './felixApi/user.service';
import { CompanyService } from './felixApi/company.service';
import { environment } from '../../environments/environment';
import { LogService } from './log.service';
import { AuthApiService } from './felixApi/auth-api.service';

@Injectable()
export class RefreshGuard  {


  constructor(
    private router: Router,
    private authApi: AuthApiService,
    private globalService: GlobalService,
    private auth: AuthService,
    private notiService: NotificationService,
    private userService: UserService,
    private compService: CompanyService,
    private logger: LogService) { }

  canActivate() {
    this.auth.currentVersion = environment.fullVersion;

    this.checkIfCorrectVersion();

    if (this.auth.getUserEmail()) {
      return true;
    } else {
      const session = this.globalService.getSessionObject();
      const email = this.auth.getMsalUserEmail();
      if (session.currentCompanyId && email) {
        return this.restoreFromCache(session, email);
      } else {
        this.router.navigate(['']);
      }
    }
  }

  checkIfCorrectVersion() {
    this.authApi.getCurrentApplicationVersion().subscribe({
      next: (appVersion) => {
        if (!appVersion || appVersion.version.trim() !== environment.majorVersion) {
          this.auth.versionIncorrect = true;
          this.auth.correctVersion = appVersion.version.trim();
        } else {
          this.auth.versionIncorrect = false;
        }
      },
      error: (err) => {
        this.logger.log('refresh-guard', '', err, 'couldn\'t get app version', true);
      }
    });
  }

  restoreFromCache(session: TruthEngineSession, email: string): Observable<boolean> {
    if (!email) {
      this.auth.signOut();
    } else {
      this.auth.setUserEmail(email);
      return this.authApi.getAuthorisedCompanies()
        .pipe(mergeMap(
          comps => {
            const company = comps.filter(x => x.id === session.currentCompanyId);
            if (company.length !== 1) {
              return of(this.error());
            }
            this.globalService.setCurrentCompany(company[0]);
            return forkJoin(
              [
                this.userService.getUserCompany(company[0].userId),
                this.compService.getCompanyConfigs()
              ]
            )
              .pipe(map(
                ([user, config]) => {
                  this.auth.setCurrentUser(user);
                  this.auth.signIn();
                  this.globalService.setCurrentCompany(company[0]);
                  this.globalService.setAreaSelected(session.areaSelected);
                  this.globalService.companyConfigurations = config;
                  return true;
                }, (err) => {
                  return this.error();
                }
              ));
          }
        ));
    }
  }

  error() {
    this.router.navigate(['']);
    this.notiService.showError('There was an error fetching data from the session so you will have to login again');
    return false;
  }
}
