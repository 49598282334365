import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { WelcomeComponent } from './welcome/welcome.component';

import { MsalGuard } from '@azure/msal-angular';
import { RefreshGuard } from './services/refresh-guard.service';
import { RecipeClassesComponent } from './recipes/recipe-classes.component';
import { PriceFileItemGroupsComponent } from './price-file/price-file-item-groups/price-file-item-groups.component';
import { PriceFileItemsComponent } from './price-file/price-file-items/price-file-items.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login/:comp', component: LoginComponent },
  { path: 'welcome', component: WelcomeComponent, canActivate: [RefreshGuard] }, // msal stopped working when this guarded
  { path: 'welcome/:comp', component: WelcomeComponent, canActivate: [MsalGuard, RefreshGuard] },
  { path: 'recipes', component: RecipeClassesComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'maintenance', component: MaintenanceComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always'},
  { path: 'price-file-groups', component: PriceFileItemGroupsComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'price-file-items', component: PriceFileItemsComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'login', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
