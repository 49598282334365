import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// import {} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


/* MATERIAL */
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';

/* DevExtreme */
import {
    DxTreeListModule,
    DxButtonModule,
    DxBoxModule,
    DxDataGridModule,
    DxPopupModule,
    DxTemplateModule,
    DxFileUploaderModule,
    DxSelectBoxModule,
    DxDropDownBoxModule,
    DxTextBoxModule,
    DxCheckBoxModule,
    DxTextAreaModule,
    DxDateBoxModule,
    DxNumberBoxModule,
    DxLoadPanelModule,
    DxHtmlEditorModule,
    DxTagBoxModule,
    DxDropDownButtonModule,
    DxTabPanelModule,
    DxFormModule
} from 'devextreme-angular';

/* COMPONENTS */
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { BackgroundLogoComponent } from './background-logo.component';

import { ErrorPopupComponent } from './error-popup/error-popup.component';
import { DebounceClickDirective } from './debounce-click';
import { GenericFilterPipe } from './genericFilter.pipe';
import { HelpComponent, HelpActivateComponent, HelpModalComponent, HelpService } from './help.component';
import { FocusElementDirective } from './focus-element.directive';
import { UpdateConfigComponent } from './update-config/update-config.component';
import { JobSearchModalComponent } from './job-search-modal/job-search-modal.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatCheckboxModule,
        MatSelectModule,
        MatTableModule,
        MatTabsModule,
        MatGridListModule,
        DxTreeListModule,
        DxButtonModule,
        DxBoxModule,
        DxDataGridModule,
        DxPopupModule,
        DxTemplateModule,
        DxFileUploaderModule,
        DxSelectBoxModule,
        DxDropDownBoxModule,
        DxTextBoxModule,
        DxCheckBoxModule,
        DxTextAreaModule,
        DxDateBoxModule,
        DxNumberBoxModule,
        DxLoadPanelModule,
        DxHtmlEditorModule,
        DxTagBoxModule,
        DxDropDownButtonModule,
        DxTabPanelModule,
        DxFormModule
    ],
    declarations: [
        HelpComponent,
        HelpActivateComponent,
        HelpModalComponent,
        LoadingSpinnerComponent,
        ErrorPopupComponent,
        BackgroundLogoComponent,
        DebounceClickDirective,
        GenericFilterPipe,
        FocusElementDirective,
        UpdateConfigComponent,
        JobSearchModalComponent
    ],
    exports: [
        HelpComponent,
        HelpActivateComponent,
        HelpModalComponent,
        CommonModule,
        FormsModule,
        DebounceClickDirective,
        GenericFilterPipe,
        FocusElementDirective,
        // mat
        MatSelectModule,
        MatTableModule,
        MatTabsModule,
        MatCheckboxModule,
        MatGridListModule,
        // dx
        DxTreeListModule,
        DxButtonModule,
        DxBoxModule,
        DxDataGridModule,
        DxPopupModule,
        DxTemplateModule,
        DxTabPanelModule,
        DxFileUploaderModule,
        DxSelectBoxModule,
        DxDropDownBoxModule,
        DxTextBoxModule,
        DxCheckBoxModule,
        DxTextAreaModule,
        DxDateBoxModule,
        DxNumberBoxModule,
        DxLoadPanelModule,
        DxHtmlEditorModule,
        DxTagBoxModule,
        DxDropDownButtonModule,
        DxFormModule,
        // ng
        ReactiveFormsModule,
        // HttpClientModule,
        BrowserAnimationsModule,
        LoadingSpinnerComponent,
        ErrorPopupComponent,
        BackgroundLogoComponent,
        JobSearchModalComponent
    ],
    providers: [
        HelpService
    ]
})
export class SharedModule { }
