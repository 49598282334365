import { Injectable } from '@angular/core';

export class HelpDictionary {
  dataField: string;
  title: string;
  helpText: string;
}

const helpDictionary: HelpDictionary[] = [{
  dataField: 'excludeInWipUntilCompletion',
  title: 'Commissions',
  helpText: 'This field indicates that for this cost centre the cost is excluded from the Job Cost and the Invoices to date figures in the WIP. There are new fields (that are by default hidden) that show the budgeted cost for these and the stand alone adjustment required.',
},
{
  dataField: 'isMarketingCosts',
  title: 'Marketing Costs',
  helpText: 'This field indicates that for this cost centre the cost is excluded from the Job Cost and the Invoices to date figures in the WIP. There are new fields (that are by default hidden) that show the budgeted cost for these and the stand alone adjustment required.',
},
{
  dataField: 'userCanCreatePoForInvoice',
  title: 'Can Create PO For Invoice',
  helpText: 'User entering an invoice can create a purchase order for the value of the invoice without purchase order permission.',
}
];




@Injectable({
  providedIn: 'root'
})
export class HelpService {
  getHelpDictionary() {
    return helpDictionary;
  }
}
